/* .custom-nav-style {
    background-image: linear-gradient(to bottom right,#b676d3,#65128c, #401056);
}

font-family: 'Dancing Script', cursive;
font-family: 'Great Vibes', cursive;
font-family: 'Cormorant Upright', serif;
font-family: 'Sacramento', cursive;
font-family: 'Tangerine', cursive;
 */

 #custom-nav-style {
background-image: url("../../Assets/full-bloom.png");
height: 100px;


 }

 nav  {
  
    background-image: url("../../Assets/full-bloom.png");
    top : 2rem;
    
     }


.custom-link-style {
    margin: 1rem  1rem 1rem 0rem;
    padding-left : 1rem;
}

 /* nav .nav-wrapper {
  
background-image: url("../../Assets/full-bloom.png");
top : 2rem;
 } */



 

 /* Text color to override Matierlize */

 nav ul a {
     color: rgb(122, 122, 122);
     font-family: 'Great Vibes', cursive;
     font-size: 2rem;
     
 }

 nav ul {
    top: -1rem;

 }

 .custom-brand-text {
    position: absolute;
    text-align: center;
    left :43%;
    color: rgb(122, 122, 122) !important;
    font-family: 'Great Vibes', cursive; 
    font-size: 2.70rem !important;
    font-stretch: expanded;
    top: 5%;
 }


 /* @media only screen and (min-width: 601px){

 nav .nav-wrapper i, nav a.sidenav-trigger, nav a.sidenav-trigger i {
     height: 64px; 
    line-height: 64px;
}

 } */

 /* @media only screen and (min-width: 993px) {
 
 nav a.sidenav-trigger {
     display: initial

     
 }
  
} */
    
    
    .sidenav {

          /* The image used */
    background-image: url("../../Assets/full-bloom.png");
    /* Full height */
    /* Center and scale the image nicely */
    background-position: center;
       
}

/* nav .sidenav-trigger {
    left :80%;
    margin-top : 1.2%;
} */

.mob-nav {
    /* margin-top : 3%; */
    width: 40px;
    
}

.social-container {
      
    float: right;
    width: 3%;
    margin-right: .5%;
    margin-top : .15%;

    
    /* -webkit-shape-outside: circle(50% at 50% 50%);
    shape-outside: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%); */
}

/* .social-container {
    width : 50px;
} */


@media only screen and (max-width: 850px) {
    .social-container {
      
        float: right;
        width: 10%;
        margin-right: 2%;
        /* margin-left: .5%; */
        margin-top : 1.50%;

        
        /* -webkit-shape-outside: circle(50% at 50% 50%);
        shape-outside: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        -webkit-clip-path: circle(50% at 50% 50%); */
    }

    custom-brand-text {
        /* position: absolute; */
        text-align: center;
        left: 43%;
        color: rgb(122, 122, 122) !important;
        font-family: 'Great Vibes', cursive;
        font-size: 2.2rem;
        font-stretch: expanded;
        top: 5%;
    }

     nav .brand-logo {
         left : 43.5%;
     } 

    .mob-nav {
        margin-right: 1%;
        /* margin-left: .5%; */
        margin-top : 15%;
    }
    nav .sidenav-trigger {
        float: left;
        position: relative;
        z-index: 1;
        height: 56px;
        /* margin: 0 18px; */
    }


}